import UtilityService from '../services/utility.service'
export default class NewsModel {
  constructor () {
    this.id = 0
    this.title = ''
    this.title_bn = ''
    this.thumbnail = ''
    this.body = ''
    this.body_bn = ''
    this.tags = []
    this.location = ''
    this.time = null
    this.slug = ''
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.title = data.title
    this.title_bn = data.title_bn
    this.body = data.body
    this.body_bn = data.body_bn
    this.tags = Array.isArray(data.tags) ? data.tags : data.tags?.split(',')
    this.location = data.location
    this.slug = data.slug
    this.time = data.time

    if (!data.thumbnail) {
      const imageUrls = data.body?.match(/http[^"]+\.(?:png|jpg|jpeg|bmp)/gi)
      this.thumbnail = imageUrls?.length ? imageUrls[0] : ''
    } else {
      this.thumbnail = data.thumbnail
    }

    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
