
import Config from './config'
var api = Config.api

const newsAPIRoot = api.BASE_URL + '/news'

class NewsService {
  createNews (newsModel) {
    return api.postCall(
      newsAPIRoot,
      newsModel,
      api.ShowAlert,
      api.ShowAlert
    )
  }

  getAllNews () {
    return api.getCall(newsAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateNews (newsModel) {
    return api.putCall(newsAPIRoot + '/' + newsModel.id, newsModel, api.ShowAlert, api.ShowAlert)
  }

  deleteNews (newsId) {
    return api.deleteCall(newsAPIRoot + '/' + newsId, api.ShowAlert, api.ShowAlert)
  }
}
export default new NewsService()
