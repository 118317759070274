<template>
  <editor
    api-key="3n57top7hh97ydopawj695f5hqxbq22xdav1muppjfhqdja5"
    :init="{
      max_height: 500,
      menubar: menubar === 'visible' ? true : false,
      extended_valid_elements: 'span[*]',
      selector: 'textarea',
      toolbar_mode: 'floating',
      setup: function(editor) {
        editor.on('click', function(e) {
          storeSelectedPosition(editor);
        });
      },
      plugins: [
      'advlist', 'anchor', 'autolink', 'charmap', 'code', 'fullscreen',
      'help', 'image', 'insertdatetime', 'link', 'lists', 'media',
      'preview', 'searchreplace', 'table', 'visualblocks', 'accordion', 'autoresize',
      'emoticons', 'media', 'pagebreak', 'quickbars', 'wordcount'
      ],
      contextmenu: 'link image table',
      toolbar: 'undo redo | bold italic underline strikethrough | align bullist numlist | link image media pagebreak | outdent indent code | styles fontfamily fontsize | emoticons preview wordcount fullscreen'
    }"
    v-model="userInput"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'TinyMceEditor',
  props: ['Body', 'menubar'],
  data: function () {
    return {
      selectedPosition: null
    }
  },
  computed: {
    userInput: {
      get: function () {
        return this.Body
      },
      set: function (newValue) {
        this.$emit('update:Body', newValue)
      }
    }
  },
  components: {
    Editor
  },
  methods: {
    insertProperty (modelName, property) {
      modelName = modelName.trim()
      property = property.trim()
      if (this.selectedPosition) {
        this.selectedPosition.execCommand(
          'mceInsertContent',
          false,
          '<span class=""> [' + property + '] </span>'
        )
      } else {
        this.userInput += '[' + property + ']'
      }
    },
    storeSelectedPosition (editor) {
      this.selectedPosition = editor
    }
  }
}
</script>
