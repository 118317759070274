<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All News</h3>
          <p class='text-subtitle text-muted'>
            View your added news and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All News
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <!-- Basic Tables start -->
    <!-- // Basic multiple Column Form section start -->
    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header d-flex justify-content-between align-items-center'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add News
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createOrUpdateNews'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title'
                          placeholder='News Title'
                          v-model="NewsModel.title"
                        />
                        <ErrorMessage class='text-danger' name='title'/>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title (In Bangla)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title_bangla'
                          placeholder='News Title (In Bangla)'
                          v-model="NewsModel.title_bn"
                        />
                        <ErrorMessage class='text-danger' name='title_bangla'/>
                      </div>
                    </div>
                    <div class='col-md-12 col-12'>
                      <div class='form-group'>
                        <label for='first-name-column'>Tags</label>
                        <VueMultiselect
                          v-model="NewsModel.selectedNewsTags"
                          tag-placeholder="Add this as new tag"
                          placeholder="Search or add a tag"
                          label="name"
                          track-by="name"
                          :options="newsTagPreOptions"
                          :multiple="true"
                          :taggable="true"
                          @tag="addNewsTag"
                        ></VueMultiselect>

                        <ErrorMessage class='text-danger' name='category'/>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Thumbnail URL</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='thumbnail'
                          placeholder='Thumbnail url'
                          v-model="NewsModel.thumbnail"
                        />
                        <ErrorMessage class='text-danger' name='thumbnail'/>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Location</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="district"
                          placeholder="District"
                          v-model="selectedDistrictId"
                          @change="bindDistricts"
                        >
                          <option disabled selected hidden value="">
                            Select District
                          </option>
                          <option v-for="district in selectedDistricts" :key="district.id" :value="district.name">
                            {{ district.name }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='type'/>
                      </div>
                    </div>

                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Time</label>
                        <Field
                          type='datetime'
                          class='form-control'
                          name='NewsTime'
                          placeholder='News date/time'
                          v-model="NewsModel.time"
                        />
                        <ErrorMessage class='text-danger' name='NewsTime'/>
                      </div>
                    </div>

                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>URL Slug</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='urlSlug'
                          placeholder='URL Slug'
                          v-model="NewsModel.slug"
                        />
                        <ErrorMessage class='text-danger' name='urlSlug'/>
                      </div>
                    </div>
                    <div class='col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>News Body</label>
                        <TinymceEditor ref="tinyMce" v-model:Body="NewsModel.Body" :menubar="'visible'" name="content"
                                       class="mb-3" v-validate="'required'" v-model.trim="NewsModel.body"
                                       :placeholder="'Write your page content here...'"></TinymceEditor>
                        <ErrorMessage class='text-danger' name='details'/>
                      </div>
                    </div>
                    <div class='col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>News Body (In Bangla)</label>
                        <TinymceEditor ref="tinyMce2" v-model:Body="NewsModel.Body_bn" :menubar="'visible'"
                                       name="content" class="mb-3" v-validate="'required'"
                                       v-model.trim="NewsModel.body_bn"
                                       :placeholder="'Write your page content here...'"></TinymceEditor>
                        <ErrorMessage class='text-danger' name='details_bangla'/>
                      </div>
                    </div>
                    <div class='form-group col-12'>
                      <div class='form-check'>
                        <div class='checkbox'>
                          <input
                            type='checkbox'
                            id='checkbox5'
                            class='form-check-input'
                            checked
                            v-model="NewsModel.status"
                          />
                          <label for='checkbox5'>Active</label>
                        </div>
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, createOrUpdateNews)'
                      >
                        {{ NewsModel.id ? 'Update' : 'Create' }}
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header' v-if="showAdvancedForm">
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Tags</th>
              <th>Location</th>
              <th>Time</th>
              <th>Thumbnail</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(news, index) in NewsList' :key='news.id'>
              <td>{{ index + 1 }}</td>
              <td>{{ news.title }}</td>
              <td>
                <p v-if="news.tags.length>0">
                    <span v-for="tag in news.tags" :key='tag'>
                      <span class="badge bg-light-info chip">
                        {{ tag }}
                      </span>
                    </span>
                </p>
              </td>
              <td>{{ news.location }}</td>
              <td>{{ news.time }}</td>
              <td><img class="img" width="140" :src="news.thumbnail" :alt="news.title.toLocaleUpperCase()"
                       @error="handleImageError"></td>
              <td>
                <span
                  :class="'badge chip' + (news.status ? ' bg-light-success' :' bg-light-danger')">{{ news.status ? 'Active' : 'InActive' }}</span>
              </td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteNews(news.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editNews(news)">Edit</button>
              </td>
            </tr>
            <tr v-if='!NewsList.length'>
              <td colspan='8' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style src='vue-multiselect/dist/vue-multiselect.css'></style>
<style scoped>
.chip {
  margin: 0px 10px 5px 0px;
}
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import NewsService from '../../services/news.service'
import TinymceEditor from '../../components/general/tinymce-editor.vue'
import NewsModel from '../../models/NewsModel'
import VueMultiselect from 'vue-multiselect'
import districtsData from '@/../public/assets/js/bd-districts.json'
import divisionData from '@/../public/assets/js/bd-divisions.json'

export default {
  name: 'News',
  data () {
    return {
      NewsModel: new NewsModel(),
      NewsList: [],
      noImgAvailable: 'https://via.placeholder.com/640x480.png/0044ff?text=news+illum',
      selectedDistricts: districtsData,
      divisionData: divisionData,
      selectedDistrictId: {},
      newsTagPreOptions: [
        {
          name: 'Announcement'
        },
        {
          name: 'Arrival'
        },
        {
          name: 'Discount'
        },
        {
          name: 'Inauguration'
        }
      ],
      openAddForm: false,
      showAdvancedForm: false
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    VueMultiselect,
    TinymceEditor
  },
  watch: {},
  mounted () {
    this.getNews()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    bindDistricts () {
      this.NewsModel.location = this.selectedDistrictId
    },
    handleImageError (event) {
      event.target.src = this.noImgAvailable
    },
    addNewsTag (tag) {
      this.NewsModel.selectedNewsTags.push({ name: tag })
      this.newsTagPreOptions.push({ name: tag })
      this.NewsModel.tags.push(tag)
    },
    getNews () {
      this.NewsList = []
      NewsService.getAllNews().then(
        response => {
          if (response.data.status) {
            this.NewsList = _.map(response.data.objects.news, function (b) {
              return new NewsModel().load(b)
            })
            this.showAdvancedForm = true
          }
        }
      )
    },
    reset () {
      this.openAddForm = false
      this.NewsModel = new NewsModel()
      this.NewsModel.selectedNewsTags = []
      this.selectedDistrictId = ''
    },
    createOrUpdateNews () {
      const vm = this
      this.NewsModel.tags = this.NewsModel.selectedNewsTags.map(item => item.name).join(',')

      if (this.NewsModel.id) {
        this.updateNews(this.NewsModel)
      } else {
        NewsService.createNews(this.NewsModel).then(
          response => {
            if (response.data.status) {
              vm.NewsList.push(new NewsModel().load(response.data.objects.news))
              this.getNews()
              vm.reset()
            } else {
              alert('<pre>' + JSON.stringify(response.data.errors) + '</pre>', 'danger')
            }
          }
        )
      }
    },
    editNews (NewsModel) {
      this.NewsModel.load(NewsModel)
      const newsTags = _.map(this.NewsModel.tags, function (tag) {
        return { name: tag }
      })
      this.NewsModel.selectedNewsTags = newsTags
      this.newsTagPreOptions = [...this.newsTagPreOptions, ...newsTags]
      this.openAddForm = true
    },
    updateNews (newsModel) {
      const vm = this
      NewsService.updateNews(newsModel).then(
        response => {
          if (response.data.status) {
            vm.NewsModel.load(response.data.objects.news)
            this.getNews()
            vm.reset()
          } else {
            alert(response.data.message + '\n<pre>' + JSON.stringify(response.data.errors) + '</pre>', 'danger')
          }
        }
      )
    },
    deleteNews (newsId) {
      const vm = this
      NewsService.deleteNews(newsId).then(
        response => {
          if (response.data.status) {
            vm.NewsList = _.filter(vm.NewsList, function (p) {
              return p.id !== newsId
            })
            vm.reset()
            this.openAddForm = false
            this.getNews()
          }
        }
      )
    }
  },
  computed: {}
}
</script>
